.navbutton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36px;
  width: 36px;
  box-shadow: -7px -7px 20px 0px #0002, -4px -4px 5px 0px #131518,
    7px 7px 20px 0px #0002, 4px 4px 5px 0px #131518,
    inset 0px 0px 0px 0px #131518, inset 0px 0px 0px 0px #131518,
    inset 0px 0px 0px 0px #131518, inset 0px 0px 0px 0px #131518;
  transition: box-shadow 0.6s cubic-bezier(0.79, 0.21, 0.06, 0.81);
  border-radius: 10px;
}

.navbutton > img {
  object-fit: contain;
  height: 40px;
}
