.navbar {
    display: flex;
    flex-direction: column;
    background-color: #1F2732;
    width: min-content;
    height: 100vh;
    padding: 20px;
    /* border-right: 2px dashed #444444;  */
    position: sticky;
    top: 0;
    left: 0; 

}

.navbutton-container{
    margin-top: 10px;
    height: 427px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.social-media-container{
    margin-top: 20px;
    height: 208px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: fit-content;
    
}