.slider-card{
    width: 600px;
    height: 400px;
    display: flex;
    justify-content: center;
    border-radius: 30px;
    background-color: #0C0F14;
    box-shadow: 0 4px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
    align-items: center;
}

.slider-card-project-image{
width: 100%;
height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.slider-card-experience-image{
    max-height:100%;
    max-width: 50%;
    object-fit: contain;
    border-radius: 10px;
}

@media (max-width: 2452px) {
    .slider-card{
        width: 600px;
        height: 400px;
    }
}

@media (max-width: 1972px) {
    .slider-card{
        width: 600px;
        height: 400px;
    }
}

@media (max-width: 1645px) {
    .slider-card{
        width: 600px;
        height: 400px;
    }
}

@media (max-width: 1334px) {
    .slider-card{
        width: 220px;
        height: 220px;
    }
}

    @media (max-width: 1142px) {
        .slider-card{
            width: 200px;
            height: 200px;
        }
}

@media (max-width: 1044px) {
    .slider-card{
        width: 180px;
        height: 180px;
    }
}


@media (max-width: 737px) {
    .slider-card{
        width: 150px;
        height: 150px;
    }
}

@media (max-width: 611px) {
    .slider-card{
        width: 100%;
        height: 300px;
    }
}

/* @media (min-width: 1972px) {
    .slider-card{
        width: 350px;
        height: 500px;
    }
} */