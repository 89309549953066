.banner {
  background-color: #0C0F14;
  flex-grow: 1;
  height: fit-content;
  /* border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px; */
  box-shadow: 0 2px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  padding-top: 20px;
  display: flex;

}

.banner-info {
  margin-left: 20px;
  color: white;
  width: min-content;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 50px;
  cursor: default;
}

.banner-info > p {
  font-family: "Quicksand", sans-serif;
  color: #ababab;
}

.banner-info-header-top {
  font-family: "Raleway Dots", cursive;
  font-size: 50px;
  letter-spacing: 8px;
  margin-top: 30px;
  margin-bottom: 0px;
  color: #ababab;
}

.banner-info-header-bottom {
  opacity: 87%;
  font-family: "Quicksand", sans-serif;
  color: white;
  font-weight: 400;
  letter-spacing: 8px;
  font-size: 60px;
  margin-top: 10px;
  margin-bottom: 0;
}

.banner-info-line {
  height: 2px;
  background-color: #c24d2c;
  width: 100%;
  margin-top: 10px;
}

.banner-info > p {
  width: 100%;
  font-size: 14px;
  font-weight: lighter;
}

.banner-info-btn {
  background-color: white;
  opacity: 87%;
  border-radius: 3px;
  width: 170px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: ease-in 0.1s;
  margin-top: 30px;
}

.banner-info-btn:hover {
  background-color: #c24d2c;
  color: white !important;
}

.banner-info-btn > h3 {
  color: #17202e;
  font-family: "Quicksand", sans-serif;
  font-weight: 700;
  font-size: small;
  margin: 2px;
}

.banner-info-about {
  margin-top: 50px;
  font-family: "Quicksand", sans-serif;
}

.banner-info-about > h2 {
  margin: 0;
  font-weight: 500;
}

.banner-info-about > h1 {
  margin: 0;
  color: #e3b100;
}

.banner-info-about > p {
  font-size: 14px;
  margin-top: 5px;
  color: #ababab;
}

.banner-infographic {
  flex-grow: 2;
  display: flex;
  justify-content: center;
}

@keyframes fadeIn {
  0% {
    opacity: 0.05%;
  }
  100% {
    opacity: 100%;
  }
}

.banner-img {
  /* display: inline-block; */
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.banner-infographic > picture {
  align-self: flex-end;
}

.banner-infographic,
.banner-info {
  animation: 1s ease-in 0s 1 fadeIn;
}

@media (max-width: 1574px) {
  .banner-info-header-top {
    font-size: 30px;
  }

  .banner-info-header-bottom {
    font-size: 40px;
  }

  .banner-info > p {
    font-size: 15px;
  }

  .banner-info-btn {
    width: 150px;
    height: 30px;
  }

  .banner-info-btn > h3 {
    font-size: medium;
  }

  .banner-info-about {
    font-family: "Quicksand", sans-serif;
  }

  .banner-info-about > h2 {
    margin: 0;
    font-weight: 500;
  }

  .banner-info-about > h1 {
    margin: 0;
    color: #e3b100;
    font-size: xx-large;
  }

  .banner-info-about > p {
    font-size: 15px;
    margin-top: 5px;
    color: #ababab;
  }

  .banner-img {
    /* display: inline-block; */
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }

  .banner-infographic > picture {
    align-self: flex-end;
  }
}

@media (max-width: 1394px) {
  .banner {
    background-color: #1f2732;
    flex-grow: 1;
    height: fit-content;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
    align-items: center;
  }

  .banner-info {
    margin-left: 20px;
    color: white;
    width: min-content;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 50px;
    cursor: default;
    align-items: center;
    text-align: center;
  }

  .banner-info-header-top {
    font-size: 30px;
  }

  .banner-info-header-bottom {
    font-size: 30px;
  }

  .banner-info > p {
    font-size: 15px;
  }

  .banner-info-btn {
    width: 240px;
    height: 30px;
  }

  .banner-info-btn > h3 {
    font-size: medium;
  }

  .banner-info-about {
    font-family: "Quicksand", sans-serif;
  }

  .banner-info-about > h2 {
    margin: 0;
    font-weight: 500;
  }

  .banner-info-about > h1 {
    margin: 0;
    color: #e3b100;
    font-size: xx-large;
  }

  .banner-info-about > p {
    font-size: 15px;
    margin-top: 5px;
    color: #ababab;
  }

  .banner-img {
    /* display: inline-block; */
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }

  .banner-infographic{
    background-color: #1C232F;
    padding-top: 20px;
    width: 100%;
  }

  .banner-infographic > picture {
    align-self: flex-end;
  }
}

@media (max-width: 1355px) {
  .banner {
    background-color: #1f2732;
    flex-grow: 1;
    height: fit-content;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
  }

  .banner-info-header-top {
    font-size: 30px;
  }

  .banner-info-header-bottom {
    font-size: 30px;
  }

  .banner-info > p {
    font-size: 15px;
  }

  .banner-info-btn {
    width: 240px;
    height: 30px;
  }

  .banner-info-btn > h3 {
    font-size: medium;
  }

  .banner-info-about {
    font-family: "Quicksand", sans-serif;
  }

  .banner-info-about > h2 {
    margin: 0;
    font-weight: 500;
  }

  .banner-info-about > h1 {
    margin: 0;
    color: #e3b100;
    font-size: xx-large;
  }

  .banner-info-about > p {
    font-size: 15px;
    margin-top: 5px;
    color: #ababab;
  }

  .banner-infographic {
    background-color: #28303C;
    padding-top: 20px;
  }

  .banner-img {
    object-fit: contain;
  }

  .banner-infographic > picture {
    margin: auto;
  }
}


@media (max-width: 465px) {
  .banner {
    background-color: #1f2732;
    flex-grow: 1;
    height: fit-content;
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
    display: flex;
    flex-direction: column-reverse;
    padding: 0;
  }

  .banner-info-header-top {
    font-size: 20px;
    margin: 0;
  }

  .banner-info-header-bottom {
    font-size: 20px;
  }

  .banner-info > p {
    font-size: 15px;
  }

  .banner-info-btn {
    width: 240px;
    height: 30px;
  }

  .banner-info-btn > h3 {
    font-size: medium;
  }

  .banner-info-about {
    font-family: "Quicksand", sans-serif;
  }

  .banner-info-about > h2 {
    margin: 0;
    font-weight: 500;
  }

  .banner-info-about > h1 {
    margin: 0;
    color: #e3b100;
    font-size: xx-large;
  }

  .banner-info-about > p {
    font-size: 15px;
    margin-top: 5px;
    color: #ababab;
  }

  .banner-infographic {
    background-color: #28303C;
    padding-top: 20px;
  }

  .banner-img {
    object-fit: contain;
    width: 100%;
    
  }

  .banner-infographic > picture {
    margin: auto;
    width: 50%;

  }
}

@media (max-width: 375px) {

  .banner-info-btn {
    width: 140px;
    height: 30px;
  }

  .banner-info-btn > h3 {
    font-size: medium;
  }

  .banner-info{
    padding: 25px;
  }

}