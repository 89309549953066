.slider-section {
  width: 100%;
  background-color: #0D1117;
  margin-top: 20px;
  padding-top: 5px;
  padding-bottom: 51px;
  padding-left: 0px;
  padding-right: 0px;
  margin-bottom: 20px;
  height: min-content;
  box-sizing: border-box;
  border-radius: 14px;
}

.slider-section > h4 {
  margin-top: 46px;
  /* margin-left: 75px; */
  margin-bottom: 30px;
  font-family: "Quicksand", sans-serif;
  color: #ababab;
  font-weight: 400;
  font-size: large;
  letter-spacing: 5px;
  visibility: hidden;
}

@keyframes fadeIn {
    0% {
      opacity: .05%;
      visibility: visible;
    }
    100%{
      opacity: 100%;
      visibility: visible;
    }
  }



.slider-section > h4,.swiper{
    animation: 1s ease-in 0s 1 fadeIn;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

.swiper {
  max-width: 100%;
  z-index: -5;
  /* height: 400px; */
  visibility: hidden;
  
}

.swiperSlide{
    cursor: crosshair;
}


/* .project {
    background-color: whitesmoke;
    width: 250px;
    height: 300px;
    border-radius: 3px;
    padding: 60px;
  } */

/* MODAL-------------------- */

@keyframes slideDown {
    0% {
      top: 0%;
      left: 50%;
    }
    100%{
      top: 50%;
      left: 50%;
    }
  }

.project-modal {
  min-width: 200px;
  width: 80%;
  height: 90%;
  position: absolute;
  left: 50%;
  top: 50%;
  background-color: #12171e;
  transform: translate(-50%, -50%);
  border-radius: 12px 12px 12px 12px;
  border-color: #12171e;
  /* box-shadow: 0px 2px 0px 0px rgb(41, 230, 255); */
  overflow-y: scroll;
  z-index: 2;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 45px;
  font-family: "Quicksand", sans-serif;
  color: #ababab;
  animation: .4s ease-in 0s 1 slideDown;
  animation-fill-mode: forwards;
}



.project-modal:focus {
  outline: 0;
}

.project-modal > img {
  object-fit: cover;
  height: 40%;
  width: 40%;
  border-radius: 14px;
}

.project-modal > h1 {
  color: #e3b100;
  margin-top: 25px;
}

.project-modal > h4{
    font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;

}

.project-modal-about-title {
  margin-top: 27px;
}

.project-modal > p {
    margin-top: 0;
  font-weight: lighter;
  font-size: 14px;
}

.project-modal > h6 {
  font-size: medium;
  margin-top: 40px;
}

.gitLink {
  color: white;
  text-decoration: none;
}

.project-modal-technology {
  width: min-content;
  height: 40px;
  background-color: #17202e;
  padding-left: 10px;
  padding-right: 10px;
  font-size: small;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: 4px;
}

.project-modal-technolgy-container {
  display: flex;
  justify-content: space-between;

}


/* width */
::-webkit-scrollbar {
  width: 8px;
}

Track
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c24d2c;
  border-radius: 10px;
}

@media (max-width: 456px) {
  .slider-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
  }

}