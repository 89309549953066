.App {
  display: flex;
  width: 85%;
  /* overflow: scroll; */
  align-items: center;
  margin: auto;
}

.main-content {
  padding: 0;
  flex-grow: 1;
  width: 100%;
}


/* @media (min-width: 2122px) {
  .App{
    width: 85%;
  }
} */

/* width */
::-webkit-scrollbar {
  width: 8px;
}

Track
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c24d2c;
  border-radius: 10px;
}


@media (max-width: 611px) {
  .App {
    display: flex;
    width: 100%;
  }
}